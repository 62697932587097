import { SET_TARIFARIO } from "./tarifarioTypes";
const initialState = {
  id: "",
  centorRxId: "",
  items: [],
};

export const tarifarioReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_TARIFARIO:
      return action.payload;

    default:
      return state;
  }
};
