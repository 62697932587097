import {
  SET_SUBGRUPOS,
  UPDATE_SUBGRUPO_IN_ARRAY,
  ADD_SUBGRUPO_IN_ARRAY,
  REMOVE_SUBGRUPO_IN_ARRAY,
} from "./subGruposTypes";

const initialState = [];
export const subGruposReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBGRUPOS:
      return action.payload;
    case UPDATE_SUBGRUPO_IN_ARRAY:
      let idx = state.findIndex((it) => it.id === action.payload.id);
      let items = [...state];
      if (idx > -1) items[idx] = action.payload;
      return items;
    case REMOVE_SUBGRUPO_IN_ARRAY:
      const filteredItems = state.filter((t) => t.id != action.payload.id);
      return filteredItems;
    case ADD_SUBGRUPO_IN_ARRAY:
      return [action.payload, ...state];
    default:
      return state;
  }
};
