import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import { dispositivo } from "../../../components/Dispositivos";

export const ModalBody = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: relative;
  top: 2rem;
  background-color: #ffffff;
  padding: 0%;
  width: 85%; 
  display: block;
`;
export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
`;

export const ModalHeaderTitle = styled.div`
    font-size: 16px;
    margin-right: auto;
    margin-left:12px;
`;

export const ModalChildrenContainer = styled.div`
    max-height: 100%;
    overflow: auto;
`;

export const ModalButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background-color: #f5f5f5;
  padding: 1% !important;
`;

export const ButtonPagar = styled.button`
  background-color: #0c8de3;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  margin: 4px 22px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  :hover {
    background-color: #707070;
    color: white;
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  max-height: 70vh;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props) => `0 0 ${(props.size / 12) * 100}%`};
  max-width: ${(props) => `${(props.size / 12) * 100}%`};
  padding-left: 10px;
  padding-right: 10px;
`;


export const TablaBodyTexto = styled.th`
  font-size: 12px;
  color: #045184;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;