import { db } from "../Config";
//import { useFirebaseApp } from "reactfire";
// función asincrona de fetch ordenes
//const firebase = useFirebaseApp();
export const fetchLabs = () => {
  return new Promise((resolve, reject) => {
    let ordenesRef = db.collection("laboratorios");
    let labs = [];
    ordenesRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          labs.push({
            id: doc.id,
            razonSocial: doc.data().razonSocial,
          });
        });
        resolve(labs);
      })
      .catch((err) => reject(err));
  });
};
