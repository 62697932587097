import React, { useState } from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import datos from "../../img/azul_datos_32.png";
import presupuesto from "../../img/azul_presupuesto_32.png";
import pago from "../../img/azul_pagos_32.png";
import prestacion from "../../img/azul_prestacion_32.png";
import diente from "../../img/azul_odontograma_32.png";
import reporte from "../../img/azul_reportes_32.png";
import { NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getKeys, getMenus } from "../Accesos/index";
import { CircularProgress } from "@material-ui/core";
import TuLogoAqui from "../../img/logos/tu_logo_aqui.png";
import { fetchUsuario } from "../../services/firebase/usuarios";
import {
  MenuLateralWrapper,
  Navbar,
  SubItemWrapper,
  StyledCircle,
} from "./styles";
import { auth } from "../../services/firebase/Config";
import { useDispatch } from "react-redux";
import { setUsuarioSesion } from "../../redux/sesion/sesionActions";
import SysDent from "../../img/sysdent-logo.png";

export const MenuLateral = ({ url, urlBase }) => {
  const [showLinks, setShowLinks] = useState(false);
  const menu = { con: false, lab: false, crx: false ,msj:false};
  const [menuState, setMenuState] = useState(menu);

  const sesion = useSelector((state) => state.sesion);
  const centroRx = useSelector((state) => state.centroRx);

  const getItemClassName = (_nombre) => {
    return menuState[_nombre] ? "shown" : "not-shown";
  };

  const generaMenu = () => {
    //const options = mappedMenus()[0];
    const options = getMenus();
    return options.map((_op) => {
      let imgPath1 = require(`../../img/${_op.iconFile}`);
      return (
        <MenuLateralWrapper>
          <div style={{ paddingLeft: "18px" }}>
            <img src={imgPath1} alt="icon" style={{ marginRight: "5px" }}></img>
            <span
              style={{
                fontWeight: "bolder",
                lineHeight: "2.5",
                color: "rgb(12,141,227)",
                cursor: "pointer",
              }}
              onClick={() => {
                const _menuState = { ...menuState };
                for (let x in _menuState) {
                  if (x === _op.key) _menuState[_op.key] = !_menuState[_op.key];
                  else _menuState[x] = false;
                }
                return setMenuState(_menuState);
              }}
            >
              {_op.nombre}
            </span>
            {_op.items && (
              <div
                className={getItemClassName(_op.key)}
                style={{ paddingLeft: "15px", width: "100%", display: "block" }}
              >
                {_op?.items?.map((it) => {
                  let imgPath = require(`../../img/${it.iconFile}`);
                  return (
                    <div style={{ margin: "8px 0" }}>
                      <NavLink
                        to={`${_op.url}${it.url}`}
                        onClick={(e) => (it.items ? e.preventDefault() : null)}
                      >
                        <img src={imgPath} alt="icon"></img>
                        <span style={{ lineHeight: "2", marginLeft: "5px" }}>
                          {it.nombre}
                        </span>
                      </NavLink>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {it.items?.length > 0 &&
                          it.items.map((_subitem) => {
                            return (
                              <NavLink to={`${_op.url}${_subitem.url}`}>
                                <SubItemWrapper>
                                  <StyledCircle />
                                  <span
                                    style={{
                                      lineHeight: "2",
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    {_subitem.nombre}
                                  </span>
                                </SubItemWrapper>
                              </NavLink>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </MenuLateralWrapper>
      );
    });
  };
  return (
    <div>
      <Navbar>
        {centroRx?.logoUrl ? (
          <img
            src={centroRx.logoUrl}
            alt="clinic logo"
            style={{ width: "248px", objectFit: "cover", padding: "10px" }}
          />
        ) : (
          <div
            style={{
              width: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={TuLogoAqui} alt="Tu Logo Aqui" />
          </div>
        )}

        <NavDropdown.Divider />

        {generaMenu()}
      </Navbar>
    </div>
  );
};
