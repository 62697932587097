import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

export const DivCharts = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  //flex-direction: column;
  @media ${dispositivo.tablet} {
    display: flex;
  }
`;
export const Contenedor = styled.div`
  display: grid;
  // border: 5px solid #e1bee7;
  background-color: #3297A9;
  // background-color: #6dbcf4;
  grid-template-columns: 1fr ;
  grid-auto-rows: minmax(100px, auto);
  gap: 15px;
  place-content: center;
  padding: 15px;
  margin: 1rem;
  @media ${dispositivo.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
export const Titulo = styled.h1`
  display: flex;
  font-size: 20px;
  justify-content: center;
  text-align: center;
`;

export const Contenido = styled.div`
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;

  @media ${dispositivo.tablet} {
    font-size: 2.5rem;
  }
`;
export const Item = styled.div`
  border: 2px;
  background-color: #ffffff;
  padding: 15px;
  color: #0060B0;
  // border: 2px solid #3a4750;
`;
export const ContenedorBienvenida = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.625em;
  text-align: center;
  
  .titulo-bienvenida{
    font-size: 2rem;
    color: #0060B0;
    @media ${dispositivo.laptop} {
      font-size: 2.5rem;
    }
  }
  .subtitulo-bienvenida{
    font-size: 1rem;
    color: #029139;
    @media ${dispositivo.laptop} {
      font-size: 1.5rem;
    }
  }
`;
