import { db } from "../Config";

export const fetchPatientByBusinessId = async (businessID) => {
  let patients = [];
  try {
    const empresaDoc = await db.collection("empresas").doc(businessID).get();

    const empresaData = empresaDoc.data();
    const nombreEmpresa = empresaData ? empresaData.razonSocial : "";

    await db
      .collection("pacientes")
      .where("empresaId", "==", businessID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          patients.push({
            id: doc.data().id,
            apellidos: doc.data().apellidos,
            nombres: doc.data().nombres,
            telefono: doc.data().telefono,
            tipDoc: doc.data().tipDoc,
            numDoc: doc.data().numDoc,
            empresaId: doc.data().empresaId,
            empresa: nombreEmpresa,
          });
        });
      });
  } catch (err) {
    throw err;
  }
  return patients;
};
