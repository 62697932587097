import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";

export const ModalBody = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: relative;
  top: 2rem;
  background-color: #ffffff;
  padding: 0%;
  width: 80%;
  display: block;
`;
export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
`;

export const ModalHeaderTitle = styled.div`
    font-size: 16px;
    margin-right: auto;
    margin-left:12px;
`;

export const ModalChildrenContainer = styled.div`
  padding: 2%;
`;

export const ModalButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background-color: #f5f5f5;
  padding: 1% !important;
`;

export const ButtonPagar = styled.button`
  background-color: #0c8de3;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  margin: 4px 22px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  :hover {
    background-color: #707070;
    color: white;
  }
`;

export const ContainerBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 20px;
  padding: 5px;
  max-height: 60vh;
  overflow-y: scroll;

`;


export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

