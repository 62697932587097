import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { NuevoTarifarioLabModal } from "../../../components/NuevoTarifarioLabModal/index";

import {
  fetchTarifarios,
  actualizaItem as actualizaItemDb,
  removeItem,
  addItem as addItemDb,
} from "../../../services/firebase/tarifariosLab/index";

import { fetchLabs } from "../../../services/firebase/labs/";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

import {
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  StyledInput,
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledLabel,
} from "./styles";

const Fila = ({ item, idx, onChange, onDelete, labId }) => {
  let ele = item.precios?.find((it) => it.labId === labId);
  return (
    <tr>
      <TablaBodyTexto data-label="Nombre">{item.nombre}</TablaBodyTexto>
      <TablaBodyTexto data-label="Precio">
        <input
          type="number"
          name="precios"
          value={ele ? ele.precio : ""}
          onChange={(e) => onChange(item, idx, e)}
          disabled={labId ? false : true}
        ></input>
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Eliminar">
        <IconButton aria-label="" onClick={() => onDelete(item)}>
          <Tooltip TransitionComponent={Zoom} placement="top" title="Eliminar">
            <DeleteIcon style={{ color: "red" }} />
          </Tooltip>
        </IconButton>
      </TablaBodyTexto>
    </tr>
  );
};

export const Tarifarios = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.sesion);
  const history = useHistory();
  const [temporizadores, setTemporizadores] = React.useState([
    { id: "", funcion: undefined },
  ]);
  const [estado, setEstado] = React.useState({
    loading: false,
    labId: "",
    labs: [],
    tarifarios: [],
    error: "",
  });
  const { addToast } = useToasts();
  const handleClick = (item) => {
    history.push({ pathname: "config", state: { data: item } });
  };
  React.useEffect(() => {
    //setEstado({ ...estado, loading: true });
    Promise.all([fetchLabs(), fetchTarifarios()]).then((values) => {
      setEstado({
        ...estado,
        labs: values[0],
        tarifarios: values[1],
        loading: false,
      });
      console.log("tarifarios", values[1]);
    });
  }, []);

  const addTemporizador = (item) => {
    //buscar si existe temporizador
    const idx = temporizadores.findIndex((t) => t.id == item.id);
    // si no existe lo crea
    if (idx < 0)
      setTemporizadores([
        ...temporizadores,
        {
          id: item.id,
          funcion: setTimeout(() => actualizaItem(item), 2000),
        },
      ]);
    else {
      if (temporizadores[idx].funcion) {
        console.log("se cancela anterior");
        clearTimeout(temporizadores[idx].funcion);
      }
      let temp = temporizadores;
      temp[idx] = {
        id: item.id,
        funcion: setTimeout(() => actualizaItem(item), 3000),
      };
      setTemporizadores(temp);
    }
  };

  const actualizaItem = async (item) => {
    setEstado({ ...estado, loading: true });
    await actualizaItemDb(item);
    setEstado({ ...estado, loading: false });
  };
  const handleChangeLab = (e) => {
    // console.log("labId", e.target.value);
    setEstado({ ...estado, labId: e.target.value });
    //traerTarifarios(e.target.value);
  };
  const traerTarifarios = async (labId) => {
    setEstado({ ...estado, loading: true });
    let tarifarios = await fetchTarifarios(labId);
    setEstado({ ...estado, loading: false, tarifarios: tarifarios });
  };

  const handleChange = (item, idx, e) => {
    let tarifarios = estado.tarifarios;
    const idy = item.precios.findIndex((t) => t.labId == estado.labId);
    if (idy > -1)
      tarifarios[idx][e.target.name][idy]["precio"] = Number(e.target.value);
    else
      tarifarios[idx]["precios"].push({
        labId: estado.labId,
        precio: Number(e.target.value),
      });
    setEstado({ ...estado, tarifarios: tarifarios });
    addTemporizador(item);
  };
  const handleDelete = async (item) => {
    try {
      await removeItem(item);
      setEstado({
        ...estado,
        tarifarios: estado.tarifarios.filter((it) => it.id != item.id),
        loading: false,
      });
      addToast("tratamiento eliminado", { appearance: "success" });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  const handleCloseModal = (e) => {
    setModalIsOpen(false);
  };
  const handleGuardar = async (item) => {
    setEstado({ ...estado, loading: true });

    await addItemDb(item);
    setModalIsOpen(false);
    setEstado({
      ...estado,
      tarifarios: [item, ...estado.tarifarios],
      loading: false,
    });
  };

  if (estado.loading || estado.loading == undefined)
    return <div>...actualizando</div>;
  else if (estado.error) return <div>error + {estado.error}</div>;
  else
    return (
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>Tarifarios</ContainerTitleText>
        </ContainerTitle>
        <ContainerTitle>
          <button
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            Nuevo
          </button>
        </ContainerTitle>
        <select
          id="labId"
          name="labId"
          value={estado.labId}
          onChange={handleChangeLab}
          onSelected={handleChangeLab}
          required
        >
          <option value="" selected disabled hidden>
            Escoja un laboratorio
          </option>
          {estado.labs.map((lab) => (
            <option value={lab.id}>{lab.razonSocial}</option>
          ))}
        </select>
        <ContainerBody>
          <Tabla>
            <TablaHeader>
              <tr>
                <TablaHeaderTexto scope="col">Nombre</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Precio</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Accion</TablaHeaderTexto>
              </tr>
            </TablaHeader>
            <TablaBody>
              {estado.tarifarios.map((item, idx) => {
                return (
                  <Fila
                    key={item.id}
                    item={item}
                    idx={idx}
                    onChange={handleChange}
                    onDelete={handleDelete}
                    labId={estado.labId}
                  />
                );
              })}
            </TablaBody>
          </Tabla>
        </ContainerBody>
        <NuevoTarifarioLabModal
          isOpen={modalIsOpen}
          handleCloseModal={handleCloseModal}
          onGuardar={handleGuardar}
        ></NuevoTarifarioLabModal>
      </MainContainer>
    );
};
