import styled from "styled-components";
import { dispositivo } from "../Dispositivos";

export const MainContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  background: #f8f8f8;
  justify-content: center;
`;

export const ContainerTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 2%;
  margin-top: 10px;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 1%;
  margin-top: 5px;
`;

export const ContainerBody = styled.div`
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-left: 5%;
  margin-top: 2%;
  margin-right: 5%;
  margin-bottom: 5%;
`;

export const ContainerLeft = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: right;
  margin-bottom:1rem;
`;

export const StyledForm = styled.div`
  width: 100%;
  height: 100%;
  padding: 2%;
  background: #fff;
  display: block;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #0c8de3;
  margin-bottom: 1rem;
`;

export const FormStyled = styled.form`
  justify-content: left;
  text-align: center;
  align-items: center;
`;

export const FormGroupField = styled.div`
  width: 80%;
`;
export const Tabla = styled.table`
  width: 100%;
  @media ${dispositivo.tablet} {
    width: 100%;
  }
  @media ${dispositivo.mobileL} {
    width: 100%;
  }
`;

export const TablaHeader = styled.thead`
  background: #fff;
  padding-left: 1px;
  padding-right: 7px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 16px;
  @media ${dispositivo.tablet} {
  }
  @media ${dispositivo.mobileL} {
  }
`;

export const TablaHeaderText = styled.td`
  width: 60%;
  @media ${dispositivo.tablet} {
    width: 60%;
  }
  @media ${dispositivo.mobileL} {
    width: 60%;
  }
`;
export const TablaHeaderLabel = styled.th`
  width: 20%;
  font-size: 15px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5%;
  text-align: left;
  @media ${dispositivo.tablet} {
    font-size: 14px;
  }
  @media ${dispositivo.mobileL} {
    font-size: 12px;
    padding-left: 1%;
  }
`;
export const ContainerSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`;

export const LabelNombre = styled.label`
  text-align: left;
  color: #040404;
  margin-bottom: 0;
  margin-right: 0.2rem;
  font-weight: bold;
`;

export const StyledInput = styled.input`
  height: 2.5rem;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  padding-left: 1em;
  line-height: 2.5;
  font-size: 0.75rem;
  width: 60%;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    width: 40%;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;


export const SelectStyle = styled.select`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  line-height: 2.5;
  font-size: 0.75rem;
  height: 2.5rem;
  width: 60%;
  text-align: end;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
    width: 40%;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;
export const SelectStyle2 = styled(SelectStyle)`
  width: 100%;
  margin-right: 0.2rem;
`;
export const StyledButton = styled.button`
height: 2.5rem;
border-radius: 1em;      
text-transform: uppercase;
cursor: pointer;
font-width: bold;
font-size: 0.9rem;
background-color: #0060b0;
color: #ffffff;
font-size: 0.9rem;
border: none;
&:hover{
  color:#000000;
  background-color: #0c8de390;
  font-width: bold;
}
@media ${dispositivo.mobileS}{
  font-size: 0.9rem;
  padding: 0.1em;
  width: 9rem;
} 
@media ${dispositivo.tablet}{
  width: 10rem;
}  
@media ${dispositivo.laptop} {      
  width: 12.5rem;
}
`;
export const StyledButtonCancelar = styled.button`
  height: 2.5rem;
  width: 100%;
  border-radius: 1em;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  font-size: 0.9rem;
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  padding: 0.5em;
  margin-right: 1rem;
}
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
  }  

  @media ${dispositivo.mobileS}{
    font-size: 0.9rem;
    width: 9rem;
  } 
  @media ${dispositivo.tablet}{
    width: 10rem;
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;
