import styled from "styled-components";
import { dispositivo } from "../../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  background: #f8f8f8;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const FlowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerBody = styled.div`
  width: 100%;
  padding: 0 4%;
  background: #fff;
  border-radius: 14px;
  display: flex;
  justify-content: center;
`;

export const Tabla = styled.table`
  width: 100%;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  color: #045184;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const StyledInput = styled.input`
  width: 30%;
  color: #707070;
  vertical-align: middle;
  text-align: center;
  border-color: #dcf2f9;
  &:focus {
    outline: none;
  }
`;

export const StyledLabel = styled.label`
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
  font-weight: bold;
`;

export const ContainerButton = styled.div`
  margin: 0 4% 1% 0;
`;

export const Button = styled.button`
  height: 2.5rem;
  border-radius: 1em;
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  font-size: 0.9rem;
  background-color: #0060b0;
  color: #ffffff;
  font-size: 0.9rem;
  border: none;
  &:hover {
    color: #000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileS} {
    font-size: 0.9rem;
    padding: 0.1em;
    width: 9rem;
  }
  @media ${dispositivo.tablet} {
    width: 10rem;
  }
  @media ${dispositivo.laptop} {
    width: 12.5rem;
  }
`;
  export const ButtonPagar = styled.button`
    background-color: #0c8de3;
    border: none;
    color: white;
    text-align: center;
    font-size: 16px;
    margin: 4px 22px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    :hover {
      background-color: #707070;
      color: white;
    }
  `;

  export const LinkCancelar = styled.button`
  background-color: #0c8de3;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  margin: 4px 22px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  :hover {
    background-color: #707070;
    color: white;
  }
`;
export const TablaHeaderTextoButtons = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: center;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;