import { createGlobalStyle } from "styled-components";
import { dispositivo } from "./components/Dispositivos"


export const GlobalStyle = createGlobalStyle`$primario: #1b278d;
    $secundario: #b90000;
    $claro: white;
    $fondo: #F8F8F8;

    html {
        font-family: "Roboto", sans-serif;
        font-size: 16px;

    }
    * {
        padding: 0;
        box-sizing: border-box;
        margin: 0;
    }

    body {
        font-family: "Roboto", sans-serif;
        height: 100vh;
        margin: 0 auto;
        background:#F8F8F8;
        overscroll-behavior: none;
    }

    .contenedor {
    width: 100%;
    margin: auto;
    display: flex;
    }
`;
