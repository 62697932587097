import React, { useState, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import { auth } from "../../services/firebase/Config";
import firebase from "firebase/app";
import {
  setUsuarioSesion,
  httpRequest,
  httpSuccess,
  httpFailure,
} from "../../redux/sesion/sesionActions";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Stardent from "../../img/auspiciadores/stardent.png";
import Digident from "../../img/auspiciadores/digident.png";
import Sysdent from "../../img/sysdent-logo.png";
import { fetchUsuario } from "../../services/firebase/usuarios";
import { useUser } from "reactfire";
import { useToasts } from "react-toast-notifications";
import { useForm } from "../../components/Hooks/useForm";
import { LoginViewWrapper, FooterWrapper } from "./styles";
import { AuthContext } from "../../components/Auth.js";

const Login = () => {
  console.log("inicio de todo");
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [{ email, password }, handleInputChange] = useForm({
    email: "",
    password: "",
  });
  const { currentUser } = useContext(AuthContext);
  const getUsuario = async (email) => {
    let usuario;
    try {
      dispatch(httpRequest());
      usuario = await fetchUsuario(email);
    } catch (err) {
      dispatch(httpFailure());
      setIsLoading(false);
      throw err;
    }
    dispatch(setUsuarioSesion(usuario));
    dispatch(httpSuccess());
    return usuario;
  };

  const verificaUsuario = async (email) => {
    console.log("verificaUsuario:", email)
    let usuario = await fetchUsuario(email);

    if (usuario.sysadmin) {
      dispatch(setUsuarioSesion(usuario));
      history.push("/inicio");
    } else
      addToast(
        "No encontramos tu cuenta!, crea una cuenta para que puedas acceder",
        { appearance: "error" }
      );
  };

  const handleLoginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithPopup(provider)
      .then((login) => {
        verificaUsuario(login.user.email);
      })
      .catch((err) => auth.signOut());
  };

  const handleLogin = () => {
    setIsLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredentials) => {
        setIsLoading(false);
        history.push("/");
      })
      .catch((err) => {
        auth.signOut();
        addToast(err.message, { appearance: "warning" });
        setIsLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLoginWithGoogle();
  };

  const renderLoginForm = () => {
    return !isLoading ? (
      <div className="form__container">
        <img src={Sysdent} alt="Sysdent logo" />

        <form onSubmit={handleSubmit}>
          <button className="form__button" type="submit">
            Ingresar con Google
          </button>
        </form>
      </div>
    ) : (
      <>
        <CircularProgress color="secondary" />
      </>
    );
  };
  React.useEffect(() => {
    console.log("currentUser", currentUser);
    if (currentUser) {
      let usuario = getUsuario(currentUser.email);
      dispatch(setUsuarioSesion(usuario));
      return <Redirect to="/" />;
    }
  }, []);
  return (
    <LoginViewWrapper>
      <div className="loginview">
        <section></section>

        {renderLoginForm()}

        <FooterWrapper>
          <footer className="footer">
            <div className="footer__container">
              <h3 className="footer__title">Sponsors:</h3>

              <div className="footer__images__container">
                <img src={Stardent} alt="startdent logo"></img>
                <img src={Digident} alt="digident logo"></img>
              </div>
            </div>
          </footer>
        </FooterWrapper>
      </div>
    </LoginViewWrapper>
  );
};
export default withRouter(Login);
