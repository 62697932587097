import {
  fetchSubGruposTra,
  updateSubGrupoTra,
  addSubGrupoTra,
  removeSubGrupoTra,
} from "../../services/firebase/subGruposTra/index";
import {
  UPDATE_SUBGRUPO_IN_ARRAY,
  ADD_SUBGRUPO_IN_ARRAY,
  SET_SUBGRUPOS,
  REMOVE_SUBGRUPO_IN_ARRAY,
} from "./subGruposTypes";

import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";

const setSubGrupos = (subGrupos) => {
  return {
    type: SET_SUBGRUPOS,
    payload: subGrupos,
  };
};
const updateSubGrupoInArray = (subGrupo) => {
  return {
    type: UPDATE_SUBGRUPO_IN_ARRAY,
    payload: subGrupo,
  };
};

const addSubGrupoInArray = (subGrupo) => {
  return {
    type: ADD_SUBGRUPO_IN_ARRAY,
    payload: subGrupo,
  };
};

const removeSubGrupoInArray = (subGrupo) => {
  return {
    type: REMOVE_SUBGRUPO_IN_ARRAY,
    payload: subGrupo,
  };
};

export const updateSubGrupo = (subGrupo) => {
  return function (dispatch) {
    dispatch(httpRequest());
    updateSubGrupoTra(subGrupo)
      .then(() => {
        dispatch(httpSuccess());
        dispatch(updateSubGrupoInArray(subGrupo));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const deleteSubGrupo = (subGrupo) => {
  return function (dispatch) {
    dispatch(httpRequest());
    removeSubGrupoTra(subGrupo)
      .then(() => {
        dispatch(httpSuccess());
        dispatch(removeSubGrupoInArray(subGrupo));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
export const addSubGrupo = (subGrupo) => {
  return function (dispatch) {
    dispatch(httpRequest());
    addSubGrupoTra(subGrupo)
      .then(() => {
        dispatch(httpSuccess());
        dispatch(addSubGrupoInArray(subGrupo));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const fetchSubGrupos = () => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchSubGruposTra()
      .then((subGrupos) => {
        dispatch(
          setSubGrupos(
            subGrupos.sort(function (a, b) {
              if (a.nombre > b.nombre) {
                return 1;
              }
              if (a.nombre < b.nombre) {
                return -1;
              }
              // a must be equal to b
              return 0;
            })
          )
        );
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
