import { useState } from "react";

export const useForm = (initialState = {}) => {
  const [formState, setFormState] = useState(initialState);
  const [isLoading] = useState(false);

  const resetForm = () => {
    setFormState(initialState);
  };

  const handleInputChange = ({ target }) => {
    if (target.value === "true" || target.value === "false") {
      setFormState({
        ...formState,
        [target.name]: target.value === "true" ? true : false,
      });
    } else {
      setFormState({
        ...formState,
        [target.name]: target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return [formState, handleInputChange, resetForm, handleSubmit, isLoading];
};
