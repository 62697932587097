import styled from "styled-components";
import { dispositivo } from "../Dispositivos";

export const MainContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  background: #f8f8f8;
  justify-content: center;
`;

export const ContainerTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 2%;
  margin-top: 10px;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 1%;
  margin-top: 5px;
`;

export const ContainerBody = styled.div`
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 2% 5% 5% 5% ;
  padding: 1rem; 
  .form--cantainer{
    @media ${dispositivo.laptop} {
      margin: 1% 15%;
    }
  }
`;

export const StyledForm = styled.div`
  width: 100%;
  height: 100%;
  padding: 2%;
  background: #fff;
  display: block;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #0c8de3;
  margin-bottom: 1rem;
`;
export const DivInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`;

export const DivCheck = styled.div`
  display: grid;
  grid-template-columns: 140px 100px 1px;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 0;
  // padding: 8px 54px 8px 0;
`;

export const ContainerLeft = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
`;
export const StyledLabel = styled.label`
  font-weight: bold;
  text-align: left;
  color: #040404;
  margin-bottom: 0;
`;

export const StyledInput = styled.input`
  height: 2.5rem;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  padding-left: 1em;
  line-height: 2.5;
  font-size: 0.75rem;
  width: 60%;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    width: 40%;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;
export const StyledSelect = styled.select`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  line-height: 2.5;
  font-size: 0.75rem;
  height: 2.5rem;
  width: 60%;
  text-align: end;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
    width: 40%;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;
export const StyledButton = styled.button`
  height: 2.5rem;
  border-radius: 0.8rem;
  width: 7rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  font-size: 0.75rem;
  border: none;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
  @media ${dispositivo.tablet}{
    width: 10rem;
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;
export const ButtonCancelar = styled(StyledButton)`
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
  }  
`;
