import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "../Modal/index";
import { useHistory } from "react-router-dom";
import { updateSubGrupo } from "../../redux/subGrupos/subGruposActions";
import { useToasts } from "react-toast-notifications";

import {
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  FormStyled,
  Tabla,
  TablaHeader,
  TablaHeaderLabel,
  StyledInput,
  StyledSelect,
  TablaHeaderText,
  LabelNombre,
  StyledButton,
  ContainerLeft,
  LabelStyled,
  ContainerSelect,
  SelectStyle,
  MainContainer,
  StyledButtonCancelar,
  StyledForm,
  SelectStyle2,
} from "./styles";

export const EditSubGrupoTra = ({ location }) => {
  const sesion = useSelector((state) => state.sesion);
  const dispatch = useDispatch();

  const enviaLab = ["CARAS", "CORONA", "NUCLEO", "OTROS_VARIOS"];
  const afectaOdonto = ["CARAS", "NUCLEO", "CORONA", "IMPLANTE", "EXODONCIA"];
  const multiplesDientes = ["CORONA", "EXODONCIA"];

  let it = location.state.data;

  const grupo = {
    id: it.id,
    grupo: it.grupo,
    nombre: it.nombre,
    afectaOdonto: it.afectaOdonto,
    envLab: it.envLab,
    multiplesDientes: it.multiplesDientes,
    protesisTipo: it.protesisTipo ? it.protesisTipo : "",
    coronaTipo: it.coronaTipo ? it.coronaTipo : "",
    color: it.color,
    estado: it.estado,
  };
  const [item, setItem] = React.useState(grupo);
  const history = useHistory();
  const { addToast } = useToasts();
  const handleChange = (e) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };

  const handleGuardar = (e) => {
    e.preventDefault();
    try {
      item.afectaOdonto = afectaOdonto.some((it) => it == item.grupo);
      dispatch(updateSubGrupo(item));
      addToast("Guardado satisfactorio", { appearance: "success" });
      history.push("/consultorios/subgrupos");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  if (sesion.loading || sesion.loading === undefined)
    return <div>...procesando</div>;
  if (sesion.error) return <div>{`Hubo un error ${sesion.error}`}</div>;
  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Configura Sub Grupo</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <form className="form form--simple" onSubmit={handleGuardar}>
          <StyledForm>
            <ContainerSelect>
              <LabelNombre htmlFor="grupo">Grupo</LabelNombre>
              <SelectStyle
                name="grupo"
                id="grupo"
                value={item.grupo}
                onChange={handleChange}
                //ref={register}
              >
                <option value="" selected disabled hidden>
                  Seleccione opcion
                </option>
                <option value="CARAS">Caras</option>
                <option value="CORONA">Corona</option>
                <option value="NUCLEO">Nucleo</option>
                <option value="IMPLANTE">Implante</option>
                <option value="EXODONCIA">Exodoncia</option>
                <option value="OTROS_VARIOS">Otros/Varios</option>
              </SelectStyle>
            </ContainerSelect>
            <ContainerSelect>
              <LabelNombre className="form__labelStyled" htmlFor="nombre">
                Nombre
              </LabelNombre>
              <StyledInput
                name="nombre"
                id="nombre"
                value={item.nombre}
                onChange={handleChange}
                required
                //ref={register}
              />
            </ContainerSelect>

            {enviaLab.some((it) => it == item.grupo) && (
              <ContainerSelect>
                <LabelNombre htmlFor="odonto">Envia Lab</LabelNombre>
                <input
                  type="checkbox"
                  name="envLab"
                  checked={item.envLab}
                  value="envLab"
                  //ref={register}
                  onChange={(e) =>
                    setItem({ ...item, [e.target.name]: e.target.checked })
                  }
                />
              </ContainerSelect>
            )}

            {afectaOdonto.some((it) => it == item.grupo) && (
              <ContainerSelect>
                {multiplesDientes.some((it) => it == item.grupo) && (
                  <ContainerSelect>
                    <LabelNombre htmlFor="odonto">
                      Multiples Dientes
                    </LabelNombre>
                    <input
                      type="checkbox"
                      name="multiplesDientes"
                      checked={item.multiplesDientes}
                      value="Multiples Dientes"
                      //ref={register}
                      onChange={(e) =>
                        setItem({ ...item, [e.target.name]: e.target.checked })
                      }
                    />
                  </ContainerSelect>
                )}
                {item.grupo == "CORONA" && (
                  <ContainerSelect>
                    <SelectStyle2
                      name="coronaTipo"
                      required
                      onChange={handleChange}
                      onSelected={handleChange}
                      value={item.coronaTipo}
                    >
                      <option value="" selected disabled hidden>
                        Seleccione opcion
                      </option>
                      <option value="CORONA_FIJA">Corona Fija</option>,
                      <option value="PUENTE">Puente</option>,
                      <option value="PROTESIS_REMOVIBLE">
                        Protesis Removible
                      </option>
                      ,
                    </SelectStyle2>
                  </ContainerSelect>
                )}

                {item.coronaTipo == "PROTESIS_REMOVIBLE" && (
                  <ContainerSelect>
                    <SelectStyle
                      name="protesisTipo"
                      required
                      onChange={handleChange}
                      onSelected={handleChange}
                      value={item.protesisTipo}
                    >
                      <option value="" selected disabled hidden>
                        Seleccione opcion
                      </option>
                      <option value="TOTAL_SUPERIOR">Total Superior</option>,
                      <option value="SUPERIOR">Superior</option>,
                      <option value="TOTAL_INFERIOR">Total Inferior</option>,
                      <option value="INFERIOR">Inferior</option>,
                      <option value="LIBRE">Libre</option>,
                    </SelectStyle>
                  </ContainerSelect>
                )}
                <ContainerSelect>
                  <LabelNombre for="favcolor">Color:</LabelNombre>
                  <input
                    type="color"
                    id="color"
                    name="color"
                    value={item.color}
                    onChange={handleChange}
                  ></input>
                </ContainerSelect>
              </ContainerSelect>
            )}
          </StyledForm>
          <ContainerLeft>
            <StyledButtonCancelar
              type="button"
              value="Cancelar"
              onClick={() => history.push("/consultorios/subgrupos")}
            >
              Cancelar
            </StyledButtonCancelar>

            <StyledButton type="submit" value="Guardar">
              Guardar
            </StyledButton>
          </ContainerLeft>
        </form>
      </ContainerBody>
    </MainContainer>
  );
};
