import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

/**
 * Posicionamiento: fix, relative
 * Modelo de caja - box model
 * Tipografia
 * Visuales
 * Otros
 */

export const LoginViewWrapper = styled.div`
  .loginview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;
    height: 100vh;
    width: 100%;
    font-size: 12px;
    background-color: #5d8fc9;
    background-image: url("https://previews.123rf.com/images/nadiinko/nadiinko1805/nadiinko180500137/102208014-dentist-orthodontics-blue-seamless-pattern-with-line-icons-dental-care-medical-equipment-braces-toot.jpg");
    background-size: 150px 150px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 10px grey;

      z-index: 10;
    }
  }

  .form {
    &__container {
      border-radius: 20px;
      background-color: #ffffff;
      padding: 20px;
      padding-bottom: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      letter-spacing: 2px;
      color: #292d31;
      z-index: 100;
      box-shadow: 0 0 10px white;
      box-shadow: 0 0 10px grey;

    }

    &__input {
      border-radius: 10px;
      background-color: #ebf0fc;
      border: none;
      outline: none;
      color: #747a81;
      margin: 10px 0;
      padding: 8px 35px;
      line-height: 2.5;
      font-size: 16px;
      font-weight: bold;
      width: 100%;

      &::placeholder {
        color: #a0a6ad;
      }
    }

    &__button {
      background-color: #007bff;
      border-radius: 10px;
      border: none;
      outline: none;
      color: #cee6ff;
      font-weight: bold;
      width: 100%;
      font-size: 16px;
      line-height: 2.5;
      margin-top: 10px;
      letter-spacing: 3;

      &--secondary {
        background-color: #e2e2e2;
        color: #a1a1a1;
      }
    }

    &__forgotpassword__container {
      text-align: left;
      margin-bottom: 20px;
    }
  }
`;

export const FooterWrapper = styled.footer`
  .footer {
    background-color: #ebf0fc;
    width: 100vw;
    padding-top: 20px;
    border: 2px solid white;
    position: relative;
    z-index: 100;

    &__container {
      max-width: 1000px;
      padding: 0 20px;
      margin: auto;
    }

    &__title {
      font-weight: bold;
      color: #585b5e;
      font-style: italic;
    }

    &__images__container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
    }

    &__images__container > img {
      height: 70px;
      width: auto;
      margin: 0 30px;
    }
  }
`;
