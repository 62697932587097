import {
  SET_MODO_APP,
  SET_FECHA_SESION,
  SET_ESTADO_COMPLETADO,
  HTTP_REQUEST,
  HTTP_SUCCESS,
  HTTP_FAILURE,
  SET_PACIENTE_SESION,
  SET_USUARIO_SESION,
} from "./sesionTypes";

const initialState = {
  loading: false,
  error: "",
  usuario: "",
  empresaId: "",
  fecha: "",
  paciente: false,
  estado: {
    prestaciones: false,
    prestacionesSesion: false,
    odontograma: false,
    cuenta: false,
  },
};

export const sesionReducers = (state = initialState, action) => {
  switch (action.type) {
    case HTTP_REQUEST:
      return { ...state, loading: true };
    case HTTP_SUCCESS:
      return { ...state, loading: false, error: "" };
    case HTTP_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SET_PACIENTE_SESION:
      return { ...state, paciente: action.payload };
    case SET_MODO_APP:
      return { ...state, modo: action.payload };
    case SET_FECHA_SESION:
      return { ...state, fecha: action.payload };
    case SET_ESTADO_COMPLETADO:
      return { ...state, estado: { ...state.estado, ...action.payload } };
    case SET_USUARIO_SESION:
      return { ...state, usuario: action.payload };

    default:
      return state;
  }
};
