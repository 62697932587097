import { db } from "../Config";

export const fetchUsuario = async (email) => {
  let usuario = {};
  await db
    .collection("usuarios")
    .where("email", "==", email)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        usuario = {
          id: doc.id,
          email: doc.data().email,
          cargo: doc.data().cargo,
          empresaId: doc.data().empresaId,
          fecha: doc.data().fecha,
          nombres: doc.data().nombres,
          perfil: doc.data().perfil,
          usuario: doc.data().usuario,
          centroRxId: doc.data().centroRxId,
          sysadmin: doc.data().sysadmin,
        };
      });
      console.log("Usuario encontrado:", usuario)
    })
    .catch((err) => {
      throw new Error(err);
    });
  return usuario;
};
