import { db } from "../Config";

export const fetchSubGruposTra = () => {
  return new Promise((resolve, reject) => {
    let subGrupos = [];
    db.collection("subGruposTra")
      .where("estado", "==", "VIGENTE")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          subGrupos.push({
            id: doc.id,
            grupo: doc.data().grupo,
            nombre: doc.data().nombre,
            color: doc.data().color,
            afectaOdonto: doc.data().afectaOdonto
              ? doc.data().afectaOdonto
              : false,
            envLab: doc.data().envLab ? doc.data().envLab : false,
            multiplesDientes: doc.data().multiplesDientes
              ? doc.data().multiplesDientes
              : false,
            coronaTipo: doc.data().coronaTipo,
            protesisTipo: doc.data().protesisTipo,
            estado: doc.data().estado,
          });
        });
        resolve(subGrupos);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addSubGrupoTra = async (item) => {
  await db
    .collection("subGruposTra")
    .doc()
    .set(item)
    .catch((err) => {
      throw err;
    });
};

export const updateSubGrupoTra = async (item) => {
  await db
    .collection("subGruposTra")
    .doc(item.id)
    .set(item)
    .catch((err) => {
      throw err;
    });
};

export const removeSubGrupoTra = async (item) => {
  await db
    .collection("subGruposTra")
    .doc(item.id)
    .update({ estado: "ELIMINADO" })
    .catch((err) => {
      throw err;
    });
};
