import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  fetchSubGrupos,
  deleteSubGrupo,
} from "../../../redux/subGrupos/subGruposActions";

import {
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  StyledInput,
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledLabel,
  FlowContainer,
  Button,
  ContainerButton,
} from "./styles";

const Fila = ({ item, onClick, onDelete }) => {
  const history = useHistory();

  return (
    <tr>
      <TablaBodyTexto>
        <input type="color" data-label="color" value={item.color} />
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Nombre">{item.nombre}</TablaBodyTexto>
      <TablaBodyTexto data-label="Config">
        <IconButton aria-label="" onClick={() => onClick(item)}>
          <Tooltip TransitionComponent={Zoom} placement="top" title="Config">
            <SettingsIcon style={{ color: "blue" }} />
          </Tooltip>
        </IconButton>
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Eliminar">
        <IconButton aria-label="" onClick={() => onDelete(item)}>
          <Tooltip TransitionComponent={Zoom} placement="top" title="Eliminar">
            <DeleteIcon style={{ color: "red" }} />
          </Tooltip>
        </IconButton>
      </TablaBodyTexto>
    </tr>
  );
};

export const SubGruposTra = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sesion = useSelector((state) => state.sesion);
  const subGrupos = useSelector((state) => state.subGrupos);
  React.useEffect(() => {
    dispatch(fetchSubGrupos());
  }, []);

  const { addToast } = useToasts();
  const handleClick = (item) => {
    history.push({ pathname: "subgrupos/edit", state: { data: item } });
  };
  const handleDelete = (item) => {
    try {
      dispatch(deleteSubGrupo(item));
      addToast("tratamiento eliminado", { appearance: "success" });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  if (sesion.loading || sesion.loading == undefined)
    return <div>...cargando</div>;
  else if (sesion.error) return <div>error + {sesion.error}</div>;
  else
    return (
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>Sub Grupos Tratamientos</ContainerTitleText>
        </ContainerTitle>
        <FlowContainer>
          <ContainerButton>
            <Button
              onClick={() => {
                history.push("subgrupos/nuevo");
              }}
            >
              Nuevo
            </Button>
          </ContainerButton>
        </FlowContainer>

        <ContainerBody>
          <Tabla>
            <TablaHeader>
              <tr>
                <TablaHeaderTexto scope="col">Color</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Nombre</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Config</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Borrar</TablaHeaderTexto>
              </tr>
            </TablaHeader>
            <TablaBody>
              {subGrupos.map((item) => {
                return (
                  <Fila
                    key={item.id}
                    item={item}
                    onClick={handleClick}
                    onDelete={handleDelete}
                  />
                );
              })}
            </TablaBody>
          </Tabla>
        </ContainerBody>
      </MainContainer>
    );
};
