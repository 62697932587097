import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import datos from "../../img/azul_datos_32.png";
import presupuesto from "../../img/azul_presupuesto_32.png";
import pago from "../../img/azul_pagos_32.png";
import prestacion from "../../img/azul_prestacion_32.png";
import diente from "../../img/azul_odontograma_32.png";
import reporte from "../../img/azul_reportes_32.png";
import generar from "../../img/azul_generar_32.png";
import historial from "../../img/azul_historial_32.png";
import { NavDropdown } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import {
  NavLinkStyledTabDropdown,
  NavItemStyledTab,
  NavLinkStyledTab,
  StyledNavDropdownTab,
  NavLinkStyled,
  StyledNavDropdown,
  TextItemMenu,
  DivTabMenuPaciente,
  FigureTab,
  NavLinkDropDown,
  ImgStyled,
} from "./styles";
import { Menus, mappedMenus, getMenus } from "../Accesos/index";

export const TabMenu = ({ url, urlBase }) => {
  const [showLinks, setShowLinks] = useState(false);
  const sesion = useSelector((state) => state.sesion);
  const generaMenu = () => {
    const options = getMenus();
    return options.map((_op, index) => {
      let imgPath = require(`../../img/${_op.iconFile}`);
      return (
        <div style={{ paddingLeft: "18px" }}>
          <NavLinkStyledTab
            to={`${url}${_op.url}`}
            onClick={(e) => (_op.items ? e.preventDefault() : null)}
            activeClassName="active"
          >
            <div>
              <Tooltip
                TransitionComponent={Zoom}
                placement="top"
                title={_op.nombre}
              >
                <FigureTab>
                  <ImgStyled
                    src={imgPath}
                    alt="icon"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  ></ImgStyled>
                </FigureTab>
              </Tooltip>
            </div>

            {_op.items && (
              <StyledNavDropdown id="collasible-nav-dropdown">
                {_op.items?.map((it) => {
                  let imgPath = require(`../../img/${it.iconFile}`);

                  return (
                    <NavLinkDropDown
                      to={`${url}${_op.url}${it.url}`}
                      onClick={(e) => (it.items ? e.preventDefault() : null)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "3px 0",
                          paddingLeft: "3px",
                        }}
                      >
                        <img
                          src={imgPath}
                          alt="icon"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "5px",
                          }}
                        ></img>
                        {it.nombre}

                        {it?.items?.length > 0 ? (
                          <StyledNavDropdown id="collasible-nav-dropdown">
                            {it.items.map((_subitem) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "3px 0",
                                    paddingLeft: "3px",
                                  }}
                                >
                                  <img
                                    src={imgPath}
                                    alt="icon"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  <NavLinkDropDown
                                    to={`${url}${_op.url}${_subitem.url}`}
                                    onClick={(e) =>
                                      _subitem.items ? e.preventDefault() : null
                                    }
                                  >
                                    {_subitem.nombre}
                                  </NavLinkDropDown>
                                </div>
                              );
                            })}
                          </StyledNavDropdown>
                        ) : null}
                      </div>
                    </NavLinkDropDown>
                  );
                })}
              </StyledNavDropdown>
            )}
          </NavLinkStyledTab>
        </div>
      );
    });

    // {_op?.items[0]?.map((it) => {
    //   let imgPath = require(`../../img/${it.iconFile}`);
    //   return (
    //     <div style={{ margin: "10px 0" }}>
    //       <NavLink to={`${_op.url}${it.url}`}>
    //         <img src={imgPath} alt="icon"></img>
    //         <span style={{ lineHeight: "2", marginLeft: "5px" }}>
    //           {it.nombre}
    //         </span>
    //       </NavLink>
    //     </div>
    //   );
    // })}

    // const MenuLateral = Menus.find(
    //   (item) =>
    //     //item.perfil === sesion.usuario.perfil && item.urlBase === urlBase
    //     item.perfil === "ODONTOLOGO" && item.urlBase === urlBase
    // );
    // if (MenuLateral == null) return null;
    // if (MenuLateral.items.length > 0)
    //   return MenuLateral.items.map((item) => {
    //     let imgPath = require(`../../img/${item.iconFile}`);
    // return (
    // <NavLinkStyledTab to={`${url}${item.url}`} activeClassName="active">
    //   <div>
    //     <Tooltip
    //       TransitionComponent={Zoom}
    //       placement="top"
    //       title={item.nombre}
    //     >
    //       <FigureTab>
    //         <ImgStyled src={imgPath}></ImgStyled>
    //       </FigureTab>
    //     </Tooltip>
    //   </div>

    //   {item.items && (
    //     <StyledNavDropdown id="collasible-nav-dropdown">
    //       {item.items.map((it) => {
    //         if (it.tipo === "item")
    //           return (
    //             <NavLinkDropDown to={`${url}${it.url}`}>
    //               {it.nombre}
    //             </NavLinkDropDown>
    //           );
    //         if (it.tipo === "div") return <NavDropdown.Divider />;
    //       })}
    //     </StyledNavDropdown>
    //   )}
    // </NavLinkStyledTab>
    // );
    //   });
  };

  return (
    <React.Fragment>
      <DivTabMenuPaciente>
        <NavItemStyledTab>{generaMenu()}</NavItemStyledTab>
      </DivTabMenuPaciente>
    </React.Fragment>
  );
};
