import styled from "styled-components";
import { dispositivo } from "../Dispositivos";

export const ContenedorPaciente = styled.div`
  

  @media ${dispositivo.tablet} {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 250px 1fr;
  }
`;
