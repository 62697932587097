import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../Dispositivos";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { navbar } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";

export const DivTabMenuPaciente = styled.div`
  background: white;
  width: 100%;
  display: flex;
  margin-top: 0%;
  position: relative;

  @media ${dispositivo.tablet} {
    display: none;
  }
`;
export const Navbar = styled.nav`
  background: #fff;
  height: 100vh;
  width: 15%;

  @media ${dispositivo.tablet} {
    width: 35%;
    height: 97%;
    position: relative;
  }
`;

export const NavbarItems = styled.div`
  background: #fff;
  width: 100%;
  display: inline-block;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  position: absolute;
  padding: inherit;
  @media ${dispositivo.tablet} {
    align-items: center;
    display: block;
  }
`;

export const NavbarItem = styled(Link)`
  color: #707070;
  padding: 15px;
  display: block;

  @media ${dispositivo.tablet} {
    font-size: 50%;
    display: inline-block;
  }
`;

export const itemNav = styled.div`
  display: inline-block;
  background: #000;
`;

//navbar navbar-expand-md navbar-light d-none d-lg-block sticky-top
export const NavItemStyledTab = styled(NavItem)`
  margin: 1%;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
export const NavItemStyled = styled(NavItem)`
  padding: 10px;
  background-color: black;
`;

export const NavLinkDropDown = styled(NavLink)`
  margin: 0%;
  padding: 0% !important;
  display: flex;
  flex-direction: column;
`;
export const NavLinkStyled = styled(NavLink)`
  display: flex;
  padding: 10px;
`;
export const NavLinkStyledTab = styled(NavLink)`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0.5%;
  @media ${dispositivo.mobileL} {
    margin: 15px;
  }

  @media ${dispositivo.tablet} {
    margin: 3%;
  }
`;
export const NavLinkStyledTabDropdown = styled(NavLink)`
  display: flex;
  margin: 15px;
  margin-right: 5px;
  align-items: center;
  text-align: center;
  padding: 3px;
`;
export const TextItemMenu = styled.h3`
  margin-left: 7px;
  padding-left: 7px;
  padding-top: 7px;
  font-size: 16px;
`;

export const StyledNavDropdown = styled(NavDropdown)`
  padding-left: 1px;
  margin-left: 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  .nav-link {
    padding-left: 66%;
  }
  padding: 0% !important;
  @media ${dispositivo.mobileL} {
    .nav-link {
      padding-left: 36%;
      padding: 0%;
    }
  }
  @media ${dispositivo.mobileM} {
    .nav-link {
      padding-left: 6%;
      padding: 0%;
    }
  }
`;

export const StyledNavDropdownTab = styled(NavDropdown)`
  padding-left: 1px;
  margin-left: 1px;

  /* .nav-link{
        padding-top:1px;
        padding-right:0px;    
    } */
  padding: 0% !important;
`;

export const FigureTab = styled.figure`
  margin: 3%;
`;

export const ImgStyled = styled.img`
  width: 130%;
  @media ${dispositivo.tablet} {
    width: 130%;
  }
  @media ${dispositivo.mobileL} {
    width: 110%;
  }
  @media ${dispositivo.mobileM} {
    width: 100%;
  }
  @media ${dispositivo.mobileS} {
    width: 100%;
  }
`;
