import React from "react";
import styles from "./styles";
import { LogoWaterMarkWrapper } from "./styles";

const LogoWaterMark = () => {
  return (
    <LogoWaterMarkWrapper>
      <img
        src={require("../../img/sysdent-header-logo.png")}
        alt="sysdent logo"
      />
    </LogoWaterMarkWrapper>
  );
};

export default LogoWaterMark;
