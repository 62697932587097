import React, { useState, useRef, useEffect } from "react";
import { ContenedorBienvenida } from "./styles";

export const Inicio = () => {
  return (
    <>
      <ContenedorBienvenida>
        <h1 className="titulo-bienvenida">Bienvenido a Sysdent</h1>
        <h2 className="subtitulo-bienvenida">
          Este es el panel de administración de Sysdent. Exitos en tu gestión!
        </h2>
      </ContenedorBienvenida>
    </>
  );
};
