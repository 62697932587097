import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  setTarifario,
  guardaTarifario,
  actualizaTarifario,
} from "../../../redux/tarifario/tarifarioActions";
import { fetchCentrosRx } from "../../../services/firebase/centrosRx/index";
import { fetchTarifario } from "../../../services/firebase/tarifarioCrx/index";
import {
  MainContainer,
  ContainerBody,
  ContainerTitleText,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  StyleInput,
  ContainerButton,
  Button,
} from "./styles";

export const Tarifario = () => {
  const [estado, setEstado] = React.useState({
    loading: false,
    tarifario: { items: [] },
    centrosRx: [],
    centroRxId: "",
    error: "",
  });
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const handleChange = (e, index) => {
    let miTarifario = { ...estado.tarifario };
    miTarifario.items[index]["valor"] = e.target.value;
    setEstado({ ...estado, tarifario: miTarifario });
  };

  const handleChangeEstado = (e, index) => {
    let miTarifario = { ...estado.tarifario };
    miTarifario.items[index]["activo"] = e.target.checked;
    setEstado({ ...estado, tarifario: miTarifario });
  };

  React.useEffect(() => {
    setEstado({ ...estado, loading: true });
    fetchCentrosRx().then((centrosRx) => {
      setEstado({ ...estado, centrosRx: centrosRx, loading: false });
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(actualizaTarifario(estado.tarifario));
      addToast("Tarifario guardado satisfactoriamente", {
        appearance: "success",
      });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handleChangeLabId = (e) => {
    let centroRxId = e.target.value;
    fetchTarifario(centroRxId).then((tarifario) => {
      setEstado({
        ...estado,
        centroRxId: centroRxId,
        tarifario: tarifario,
        loading: false,
      });
    });
  };

  if (estado.loading) return <div>...Cargando</div>;
  return (
    <MainContainer>
      <form onSubmit={handleSubmit}>
        <select
          id="centroRxId"
          name="centroRxId"
          value={estado.centroRxId}
          onChange={handleChangeLabId}
          required
        >
          <option value="" selected disabled hidden>
            Escoja un Centro Rx
          </option>
          {estado.centrosRx.map((crx) => (
            <option value={crx.id}>{crx.razonSocial}</option>
          ))}
        </select>

        <fieldset>
          <ContainerTitleText>
            <legend>Tarifario</legend>
          </ContainerTitleText>
          <ContainerBody>
            <Tabla>
              <TablaHeaderTexto>
                <tr>
                  <TablaHeaderTexto>Grupo</TablaHeaderTexto>
                  <TablaHeaderTexto>Tipo</TablaHeaderTexto>
                  <TablaHeaderTexto>Comisión</TablaHeaderTexto>
                  <TablaHeaderTexto>Aplica</TablaHeaderTexto>
                </tr>
              </TablaHeaderTexto>
              <TablaBody>
                {estado.tarifario.items.map((item, index) => (
                  <tr>
                    <TablaBodyTexto>{item.grupo}</TablaBodyTexto>
                    <TablaBodyTexto>{item.llave}</TablaBodyTexto>
                    <TablaBodyTexto>
                      <StyleInput
                        type="text"
                        value={item.valor}
                        onChange={(e) => handleChange(e, index)}
                      ></StyleInput>
                    </TablaBodyTexto>
                    <TablaBodyTexto>
                      <input
                        type="checkbox"
                        checked={item.activo}
                        onChange={(e) => handleChangeEstado(e, index)}
                      ></input>
                    </TablaBodyTexto>
                  </tr>
                ))}
              </TablaBody>
            </Tabla>
            <ContainerButton>
              <Button type="submit" value="Guardar"></Button>
            </ContainerButton>
          </ContainerBody>
        </fieldset>
      </form>
    </MainContainer>
  );
};
