import { db, firestorage } from "../Config";
//import { useFirebaseApp } from "reactfire";
// función asincrona de fetch ordenes
//const firebase = useFirebaseApp();
export const fetchCentrosRx = () => {
  return new Promise((resolve, reject) => {
    let centrosRxRef = db.collection("centrosRx");
    let centrosRx = [];
    centrosRxRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          centrosRx.push(doc.data());
        });
        resolve(centrosRx);
      })
      .catch((err) => reject(err));
  });
};

export const getCentroRxById = (empresaId) => {
  let empresaRef = db.collection("centrosRx").doc(empresaId);
  return new Promise((resolve, reject) => {
    let empresa = {};
    empresaRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          empresa = {
            ruc: doc.data().ruc,
            id: doc.data().id,
            razonSocial: doc.data().razonSocial,
            telefono: doc.data().telefono,
            logoUrl: doc.data().logoUrl,
            email: doc.data().email,
          };
          resolve(empresa);
        } else {
          reject("no encontro la empresa");
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const saveEmpresa = (emp) => {
  return new Promise((resolve, reject) => {
    const empRef = db.collection("centrosRx").doc(emp.id);
    emp.nombre = emp.razonSocial;
    empRef
      .set(emp)
      .then((docRef) => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadImage = async (file) => {
  const storageRef = firestorage.ref();
  const fileRef = storageRef.child(file.name);
  await fileRef.put(file);
  const fileUrl = await fileRef.getDownloadURL();
  return fileUrl;
};
