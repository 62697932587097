import { db } from "../Config";

export const findTemplateByName = async (name) => {
  return new Promise((resolve, reject) => {
    db.collection("plantillaMeta")
      .where("nombre", "==", name)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          resolve(doc.data());
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const findAllTemplateIsNotCitas = () => {
  return new Promise((resolve, reject) => {
    db.collection("plantillaMeta")
      .where("campaña", "!=", "Citas")
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const templates = querySnapshot.docs.map(doc => doc.data());
          resolve(templates);
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
