import React from "react";
import { useState } from 'react';
import ReactDOM from "react-dom";
import Dropdown from '../../Dropdown/index';
import Gateway from "../../../gateways/api"
import CloseIcon from "@material-ui/icons/Close";
import { ModalBody, ModalContainer, ModalHeaderContainer, ModalHeaderTitle, ModalButton, ModalChildrenContainer, ContainerBody, Column } from "./style";
import "./styles.css";

const Buttons = [
  { label: "Personalizado", value: 'Personalizado' },
  { label: "Ir al sitio web", value: 'Ir al sitio web' },
  { label: "Llamar al número de teléfono", value: 'Llamar al número de teléfono' },
  { label: "Copiar codigo de oferta", value: 'Copiar codigo de oferta' },
];

export const Modal = (props) => {
  const [template, setTemplate] = useState({ body: { type: 'BODY', text: '', items: [] }, header: { type: 'HEADER', format: 'TEXT', text: '' }, footer: { type: 'FOOTER', text: 'WhatsApp Business Platform sample message' }, name: '', language: '', category: '', businessId: '' });
  const [variables, setVariables] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const lastNumber = (template.body.text.match(/{{(\d+)}}/g) || [])
      .map(match => parseInt(match.match(/{{(\d+)}}/)[1]))
      .reduce((max, curr) => Math.max(max, curr), 0);
    const isLastClosed = template.body.text.endsWith(`${lastNumber}}}`);
    if (value.endsWith('{{') && !isLastClosed) {
      setTemplate(updateNestedField(template, name, `${value}${lastNumber + 1}}}`));
      variables.push("");
    } else {
      setTemplate(updateNestedField(template, name, value));
    }
  };

  const handleSetVariable = (variable) => { }

  const updateNestedField = (object, path, value) => {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const nestedObject = keys.reduce((obj, key) => obj[key], object);
    nestedObject[lastKey] = value;
    return { ...object };
  };

  const handleVariableChange = (index, value) => {
    const newVariables = [...variables];
    newVariables[index] = value;
    setVariables(newVariables);
  };

  const sendTemplate = async () => {
    template.body.items = [...variables];
    template.header.items = [];
    try {
      let {data} = await Gateway.MetaWhatsAppApi.crearPlantilla(mapperVariablesTemplate(template), template.businessId);
      if(data) props.onhandleSave(true);
    } catch (error) {
      throw new Error('Error fetching templates: ' + error.message);
    }
  }

  const mapperVariablesTemplate = (template) => {
    const { name, language, category, header, body, footer } = template;
    return {
      name, language, category,
      components: [
        mapComponent(header, {
          format: header.format,
          ...verifyFormatHeader(header)
        }),
        mapComponent(body, {
          text: body.text,
          example: {
            body_text: body.items
          }
        }),
        mapComponent(footer, {
          text: footer.text
        })
      ]
    };
  }

  const mapComponent = (component, extraProps = {}) => {
    if (!component || typeof component != 'object') {
      throw new Error('Component is invalid');
    }
    return {
      type: component.type,
      ...extraProps
    };
  };


  const verifyFormatHeader = (data) => {
    if (data.format === "TEXT") {
      if (data.items.length != 0) {
        return { text: data.text, example: { header_text: data.items } }
      } else {
        return { text: data.text }
      }
    } else {
      return { example: { header_handle: data.items } }
    }
  }

  if (!props.isOpen) return null;



  return ReactDOM.createPortal(
    <ModalBody>
      <ModalContainer>
        <ModalHeaderContainer>
          <ModalHeaderTitle> {props.title} </ModalHeaderTitle>
          <ModalButton onClick={props.onClose}>
            <CloseIcon />
          </ModalButton>
        </ModalHeaderContainer>
        <ModalChildrenContainer>
          <ContainerBody>
            <Column>
              <div class="form-group row">
                <label for="colFormLabelSm" class="col-sm-2 col-form-label col-form-label-sm">Empresa <span class="mandatory">*</span></label>
                <div class="col-sm-12">
                  <select name="businessId" value={template.businessId} onChange={handleChange} class="form-select form-control" aria-label="Default select example">
                    <option value="" selected disabled>seleccionar Empresa</option>
                    <option value="310678535464335">Clarus Dent</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="colFormLabelSm" class="col-sm-2 col-form-label col-form-label-sm">Nombre <span class="mandatory">*</span></label>
                <div class="col-sm-12">
                  <input type="text" name="name" value={template.name} onChange={handleChange} class="form-control" id="colFormLabelSm" />
                </div>
              </div>
              <div class="form-group row">
                <label for="colFormLabelSm" class="col-sm-2 col-form-label col-form-label-sm">Categoria <span class="mandatory">*</span></label>
                <div class="col-sm-12">
                  <select name="category" value={template.category} onChange={handleChange} class="form-select form-control" aria-label="Default select example">
                    <option value="" selected disabled>seleccionar categoria</option>
                    <option value="MARKETING">Marketing</option>
                    <option value="UTILITY">Utilidad</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="colFormLabelSm" class="col-sm-2 col-form-label col-form-label-sm">Idioma <span class="mandatory">*</span></label>
                <div class="col-sm-12">
                  <select name="language" value={template.language} onChange={handleChange} class="form-select form-control" aria-label="Default select example">
                    <option value="" selected disabled>seleccionar idioma</option>
                    <option value="us">Ingles</option>
                    <option value="es">Español</option>
                  </select>
                </div>
              </div>
              <p for="basic-url"><strong>Encabezado</strong></p>
              <div class="input-group mb-3">
                <input type="text" name="header.text" value={template.header.text} onChange={handleChange} class="form-control" id="basic-url" aria-describedby="basic-addon3" />
              </div>
              <p for="basic-url"><strong>Cuerpo</strong> <span class="mandatory">*</span></p>
              <samp>Ingrese texto en el idioma que seleccionaste.</samp>
              <div class="input-group mb-3">
                <textarea type="text" name="body.text" class="form-control" maxlength="1024" cols="45" rows={4} id="basic-url" value={template.body.text}
                  onChange={handleChange} aria-describedby="basic-addon3" />
              </div>
              {variables.length > 0 && (
                <div class="content-variable">
                  <p htmlFor="basic-url"><strong>Ejemplos de contenido del cuerpo</strong></p>
                  <span class="info">
                    Agrega un ejemplo en cada variable del texto del cuerpo para la revision de tu plantilla de mensaje.
                    No uses información verdadera de clientes. Meta, revisa las plantillas y los parámetros de las variables para garantizar la seguridad
                    e integridad de su información.
                  </span>
                  <p class="mt-2" htmlFor="basic-url"><strong>Cuerpo</strong></p>
                  {variables.map((element, index) => (
                    <div class="form-group row" key={index}>
                      <label htmlFor="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm"> &#123;&#123;{index + 1}&#125;&#125;</label>
                      <div class="col-sm-10">
                        <input type="text"
                          value={element}
                          class="form-control form-control-sm"
                          id={`colFormLabelSm_${index}`}
                          onChange={(e) => handleVariableChange(index, e.target.value)} />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Column>
            <Column>
              <div class="colum-panel">
                <div class="panel-container">
                  <div class="panel-template-header">
                    <span> Vista previa del mensaje</span>
                  </div>
                  <div class="panel-template">
                    <div class="template">
                      <div class="template-header">
                        <samp>{template.header.text}</samp>
                      </div>
                      <div class="template-body">
                        <span>{template.body.text}</span>
                        <div class="template-footer">
                          <samp>
                            {template.footer.text}
                          </samp>
                        </div>
                        <span class="time">12.29 pm</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p class="mt-2" for="basic-url"><strong>Pie de Pagina</strong><span> Opcional</span></p>
              <samp>Agrega una breve línea de texto en la parte inferior de la plantilla de mensaje.</samp>
              <div class="input-group mb-3">
                <textarea type="text" name="footer.text" value={template.footer.text} onChange={handleChange} class="form-control  form-control-sm" id="basic-url" aria-describedby="basic-addon3" />
              </div>
              <p class="mt-2" for="basic-url"><strong>Botones</strong><span> Opcional</span></p>
              <samp>Crea botones que permitan a los clientes responder a tu mensaje o realizar una acción..</samp>
              <Dropdown variables={Buttons} name={"Agregar botón"} onSetVariable={(variable) => handleSetVariable(variable)} />
            </Column>
          </ContainerBody>
        </ModalChildrenContainer>
        <div class="modal-footer">
          <button class="btn btn-secondary" onClick={props.onClose}>Cancelar</button>
          <button class="btn btn-primary" onClick={sendTemplate}>Guardar</button>
        </div>
      </ModalContainer>
    </ModalBody>,
    document.getElementById("modal")
  );
};
