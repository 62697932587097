import styled from "styled-components";
import { dispositivo } from "../../Dispositivos";

export const HeaderProfileItemWrapper = styled.div`
  .main-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &__img {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      margin-right: 5px;
    }

    &__name {
      display: none;
      font-size: 14px;
      color: white;
      font-weight: bolder;
      margin: 0;

      @media ${dispositivo.tablet} {
        display: inline-block;

      }
    }

    &__content {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: white;
      font-weight: bolder;
      padding: 0;
      z-index: 100;

      &__list {
        list-style-type: none;
        font-size: 14px;
        color: #0c8de3;

        &__item {
          cursor: pointer;
          padding: 5px 7px;

          &:hover {
            background-color: #bfe6ff;
          }
        }
      }
    }
    .hide {
      display: none;
    }

    &:hover .hide {
      display: block;
      transition: 0.3s;
    }
  }
`;
