import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "../Modal/index";
import { useHistory } from "react-router-dom";
import { addSubGrupoTra } from "../../services/firebase/subGruposTra/index";
import { useToasts } from "react-toast-notifications";

import {
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledForm,
  DivInput,
  StyledLabel,
  StyledInput,
  StyledSelect,
  StyledButton,
  ButtonCancelar,
  ContainerLeft,
  MainContainer,
  DivCheck,
} from "./styles";
import { InputGroupButtonDropdown } from "reactstrap";

export const NuevoSubGrupoTra = () => {
  const sesion = useSelector((state) => state.sesion);
  const dispatch = useDispatch();
  const grupo = {
    nombre: "",
    color: "#000",
    afectaOdonto: false,
    grupo: "",
    aplica: "",
    envLab: false,
    multiplesDientes: false,
    protesisTipo: "",
    coronaTipo: "",
    estado: "VIGENTE",
  };

  const enviaLab = ["CARAS", "CORONA", "NUCLEO", "OTROS_VARIOS"];

  const afectaOdonto = ["CARAS", "NUCLEO", "CORONA", "IMPLANTE", "EXODONCIA"];

  const multiplesDientes = ["CORONA", "EXODONCIA"];
  const [item, setItem] = React.useState(grupo);
  const history = useHistory();
  const { addToast } = useToasts();

  const handleChange = (e) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };

  const handleGuardar = (e) => {
    e.preventDefault();
    try {
      item.estado = "VIGENTE";
      item.afectaOdonto = afectaOdonto.some((it) => it == item.grupo);
      addSubGrupoTra(item);
      addToast("Guardado satisfactorio", { appearance: "success" });
      history.push("/consultorios/subgrupos");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  if (sesion.loading || sesion.loading === undefined)
    return <div>...procesando</div>;
  if (sesion.error) return <div>{`Hubo un error ${sesion.error}`}</div>;
  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Nuevo Sub Grupo</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <form
          className="form form--simple form--cantainer "
          onSubmit={handleGuardar}
        >
          <StyledForm>
            <DivInput>
              <StyledLabel htmlFor="grupo">Grupo:</StyledLabel>
              <StyledSelect
                name="grupo"
                id="grupo"
                value={item.grupo}
                onChange={handleChange}
                //ref={register}
              >
                <option value="" selected disabled hidden>
                  Seleccione opción
                </option>
                <option value="CARAS">Caras</option>
                <option value="CORONA">Corona</option>
                <option value="NUCLEO">Nucleo</option>
                <option value="IMPLANTE">Implante</option>
                <option value="EXODONCIA">Exodoncia</option>
                <option value="OTROS_VARIOS">Otros/Varios</option>
              </StyledSelect>
            </DivInput>
            <DivInput>
              <StyledLabel htmlFor="nombre">Nombre:</StyledLabel>
              <StyledInput
                type="text"
                name="nombre"
                id="nombre"
                value={item.nombre}
                onChange={handleChange}
                required
                //ref={register}
              />
            </DivInput>

            {afectaOdonto.some((it) => it == item.grupo) && (
              <div>
                {item.grupo == "CORONA" && (
                  <DivInput>
                    <StyledLabel>Tipo de corona:</StyledLabel>
                    <StyledSelect
                      name="coronaTipo"
                      required
                      onChange={handleChange}
                      onSelected={handleChange}
                      value={item.coronaTipo}
                    >
                      <option value="" selected disabled hidden>
                        Seleccione opción
                      </option>
                      <option value="CORONA_FIJA">Corona Fija</option>,
                      <option value="PUENTE">Puente</option>,
                      <option value="PROTESIS_REMOVIBLE">
                        Protesis Removible
                      </option>
                      ,
                    </StyledSelect>
                  </DivInput>
                )}

                {item.coronaTipo == "PROTESIS_REMOVIBLE" && (
                  <DivInput>
                    <StyledLabel>Tipo de protesis:</StyledLabel>
                    <StyledSelect
                      name="protesisTipo"
                      required
                      onChange={handleChange}
                      onSelected={handleChange}
                      value={item.protesisTipo}
                    >
                      <option value="" selected disabled hidden>
                        Seleccione opción
                      </option>
                      <option value="TOTAL_SUPERIOR">Total Superior</option>,
                      <option value="SUPERIOR">Superior</option>,
                      <option value="TOTAL_INFERIOR">Total Inferior</option>,
                      <option value="INFERIOR">Inferior</option>,
                      <option value="LIBRE">Libre</option>,
                    </StyledSelect>
                  </DivInput>
                )}

                <div>
                  <DivInput>
                    <StyledLabel for="favcolor">Color:</StyledLabel>
                    <input
                      type="color"
                      id="color"
                      name="color"
                      value={item.color}
                      onChange={handleChange}
                    ></input>
                  </DivInput>

                  {multiplesDientes.some((it) => it == item.grupo) && (
                    <DivInput>
                      <StyledLabel htmlFor="odonto">
                        Multiples Dientes:
                      </StyledLabel>
                      <input
                        type="checkbox"
                        name="multiplesDientes"
                        checked={item.multiplesDientes}
                        value="Multiples Dientes"
                        //ref={register}
                        onChange={(e) =>
                          setItem({
                            ...item,
                            [e.target.name]: e.target.checked,
                          })
                        }
                      />
                    </DivInput>
                  )}
                </div>
              </div>
            )}
            {enviaLab.some((it) => it == item.grupo) && (
              <DivInput>
                <StyledLabel htmlFor="odonto">Envia Lab:</StyledLabel>
                <input
                  type="checkbox"
                  name="envLab"
                  checked={item.envLab}
                  value="envLab"
                  //ref={register}
                  onChange={(e) =>
                    setItem({ ...item, [e.target.name]: e.target.checked })
                  }
                />
              </DivInput>
            )}
          </StyledForm>
          <ContainerLeft>
            <ButtonCancelar
              type="button"
              value="Cancelar"
              onClick={() => history.push("/consultorios/subgrupos")}
            >
              Cancelar
            </ButtonCancelar>

            <StyledButton type="submit" value="Guardar">
              Guardar
            </StyledButton>
          </ContainerLeft>
        </form>
      </ContainerBody>
    </MainContainer>
  );
};
