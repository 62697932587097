export const Menus = [
  {
    menu: "lateral",
    key: "con",
    perfil: "ADMIN",
    nombre: "Consultorios",
    iconFile: "azul_calendario.png",
    url: "/consultorios",
    items: [
      {
        nombre: "Sub Grupos",
        url: "/subgrupos",
        iconFile: "azul_generar_32.png",
      },
    ],
  },

  {
    menu: "lateral",
    key: "crx",
    perfil: "ADMIN",
    nombre: "Centros Rx",
    iconFile: "azul_laboratorio.png",
    url: "/centrosrx",
    items: [
      {
        nombre: "Tarifarios",
        url: "/tarifarios",
        iconFile: "azul_reportes_32.png",
      },
      {
        nombre: "Comisiones",
        url: "/comisiones",
        iconFile: "azul_reportes_32.png",
      },
    ],
  },

  {
    menu: "lateral",
    key: "lab",
    perfil: "ADMIN",
    nombre: "Laboratorios",
    iconFile: "azul_laboratorio.png",
    url: "/laboratorios",
    items: [
      {
        nombre: "Tarifarios",
        url: "/tarifarios",
        iconFile: "azul_reportes_32.png",
      },
    ],
  },

  {
    menu: "lateral",
    key: "msj",
    perfil: "ADMIN",
    nombre: "Mensajes",
    iconFile: "azul_laboratorio.png",
    url: "/mensajes",
    items: [
      {
        nombre: "Listar Plantillas",
        url: "/listaplantillas",
        iconFile: "azul_reportes_32.png",
      },
      {
        nombre: "Lista de Empresas",
        url: "/listaempresas",
        iconFile: "azul_reportes_32.png",
      },
    ],
  },
];

export const getMenus = () => {
  return Menus.filter(
    (item) => item.menu === "lateral" && item.perfil === "ADMIN"
  );
};

export const getKeys = () => Menus.map(({ key }) => ({ [key]: false }));
