import React, { useState, useEffect } from 'react';
import './style.css'; 

const MultiSelect = ({ options, onChange }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showOptions, setShowOptions] = useState(false);

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const toggleOption = (option) => {
        if (selectedOptions.find(selectedOption => selectedOption.value === option.value)) {
            setSelectedOptions(selectedOptions.filter(selectedOption => selectedOption.value !== option.value));
        } else {
            setSelectedOptions(prevOptions => [...prevOptions, option]);
        }
    };

    const filterOptions = (option) => {
        return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const removeOption = (option) => {
        setSelectedOptions(selectedOptions.filter(selectedOption => selectedOption.value !== option.value));
    };

    useEffect(() => {
        onChange(selectedOptions);
    }, [selectedOptions, onChange]);

    const handleOptionClick = (option) => {
        toggleOption(option);
    };

    return (
        <div className="multi-select">
            <div className="selected-options" onClick={toggleOptions}>
                {selectedOptions.map((option) => (
                    <div key={option.value} className="selected-option">
                        {option.label}
                        <button className="remove-option" onClick={() => removeOption(option)}>x</button>
                    </div>
                ))}
                <input
                    type="text"
                    className="search-input"
                    placeholder="Buscar..."
                    value={searchTerm}
                    onChange={handleInputChange}
                />
            </div>
            {showOptions && (
                <div className="options">
                    {options.filter(filterOptions).map((option) => (
                        <div
                            key={option.value}
                            className={`option ${selectedOptions.find(selectedOption => selectedOption.value === option.value) ? 'selected' : ''}`}
                            onClick={() => handleOptionClick(option)}>
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelect;
