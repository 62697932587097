import React, { useContext, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import Layout from "./Layout";
import { AuthProvider, AuthContext } from "./Auth";
import NotFound from "./NotFound";
import Login from "../pages/Login";

import { useDispatch, useSelector } from "react-redux";
import { setFechaSesion } from "../redux/sesion/sesionActions";
import { GlobalStyle } from "../GlobalStyles";
import { PrivateRoute } from "./PrivateRoute";
import { fetchUsuario } from "../services/firebase/usuarios";
import { NuevoSubGrupoTra } from "../components/NuevoSubGrupoTra/index";
import { EditSubGrupoTra } from "../components/EditSubGrupoTra/index";
import { SubGruposTra } from "../pages/cons/SubGruposTra/index";
import { Tarifario } from "../pages/crxs/Tarifario/index";
import { Inicio } from "../pages/Inicio/index";
import { Tarifarios } from "../pages/labs/Tarifarios/index";
import { ListaPlantillas } from "../pages/cons/PlantillaMensaje/index";
import { ListaEmpresas } from "../pages/cons/EmpresaMensaje/index";
//Paciente
//Clinica
import { LayoutPaciente } from "./LayoutPaciente/index";
import {
  setUsuarioSesion,
  httpRequest,
  httpSuccess,
  httpFailure,
} from "../redux/sesion/sesionActions";
import { auth } from "../services/firebase/Config";
import LogoWaterMark from "./LogoWaterMark/";

const getFecha = () => {
  const fecha = new Date();
  const a = fecha.getFullYear();
  const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const d = fecha.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
};

const MainApp = () => {
  const { currentUser } = useContext(AuthContext);
  const [usuario, setUsuario] = useState(null);
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.sesion);
  let { path, url } = useRouteMatch();
  const getUsuario = async (email) => {
    try {
      dispatch(httpRequest());
      let usuario = await fetchUsuario(email);
      dispatch(setUsuarioSesion(usuario));
      dispatch(httpSuccess());
      setUsuario(usuario);
    } catch (err) {
      dispatch(httpFailure());
      throw err;
    }
  };

  React.useEffect(() => {
    if (currentUser) getUsuario(currentUser.email);
    else getUsuario(auth.currentUser.email);
  }, []);

  return (
    <BrowserRouter>
      <LayoutPaciente url={url} path={path}>
        <Switch>
          <PrivateRoute
            exact
            path={`/centrosrx/tarifarios`}
            component={Tarifario}
          />
          <PrivateRoute exact path={`/inicio`} component={Inicio} />
          <PrivateRoute
            exact
            path={`/consultorios/subgrupos`}
            component={SubGruposTra}
          />
          <PrivateRoute
            exact
            path={`/consultorios/subgrupos/nuevo`}
            component={NuevoSubGrupoTra}
          />
          <PrivateRoute
            exact
            path={`/consultorios/subgrupos/edit`}
            component={EditSubGrupoTra}
          />

          <PrivateRoute
            exact
            path={`/laboratorios/tarifarios`}
            component={Tarifarios}
            />
          <PrivateRoute
            exact
            path={`/mensajes/listaplantillas`}
            component={ListaPlantillas}
          />
          <PrivateRoute
            exact
            path={`/mensajes/listaempresas`}
            component={ListaEmpresas}
          />
          <Route component={NotFound}></Route>
        </Switch>
        <LogoWaterMark />
      </LayoutPaciente>
    </BrowserRouter>
  );
};

function App() {
  const dispatch = useDispatch();

  dispatch(setFechaSesion(getFecha()));

  return (
    <AuthProvider>
      <BrowserRouter>
        <GlobalStyle />
        <Layout>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute path="/" component={MainApp} />
            <Route component={<h1>Ingreso aqui</h1>}></Route>
          </Switch>
        </Layout>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
