import styled from "styled-components";
import { dispositivo } from "../Dispositivos";

export const MainContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  background: #f8f8f8;
  justify-content: center;
`;

export const ContainerTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 2%;
  margin-top: 10px;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 1%;
  margin-top: 5px;
`;

export const ContainerBody = styled.div`
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-left: 5%;
  margin-top: 2%;
  margin-right: 5%;
  margin-bottom: 5%;
`;

export const ContainerLeft = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: right;
`;

export const FormStyled = styled.form`
  justify-content: left;
  text-align: center;
  align-items: center;
`;

export const FormGroupField = styled.div`
  width: 80%;
`;
export const LabelStyled = styled.label`
  text-align: left;
  margin: 1%;
`;

export const Tabla = styled.table`
  width: 100%;
`;

export const TablaHeader = styled.thead`
  background: #fff;
  padding-left: 1px;
  padding-right: 7px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 16px;
`;

export const TablaHeaderText = styled.td`
  width: 60%;
`;
export const TablaHeaderLabel = styled.th`
  width: 20%;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  font-size: 12px;
  padding-left: 1%;

  padding-bottom: 8px;

  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 15px;
    padding-left: 5%;
  }
`;

export const StyledInput = styled.input`
  width: 90%;
  color: #707070;
  vertical-align: middle;
  text-align: right;
  padding: 0.5%;
  border: 1px solid #707070;
  font-size: 12px;
  &:focus {
    outline: none;
  }
  @media ${dispositivo.mobileL} {
    width: 90%;
    font-size: 14px;
  }

  @media ${dispositivo.tablet} {
    width: 80%;
    font-size: 15px;
  }
`;

export const StyledSelect = styled.select`
  width: 90%;
  color: #707070;
  vertical-align: middle;
  text-align: right;
  font-size: 12px;
  border: 1px solid #707070;
  &:focus {
    outline: none;
  }
  @media ${dispositivo.mobileL} {
    width: 90%;
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    width: 80%;
    font-size: 15px;
  }
`;

export const StyledButton = styled.button`
  background-color: #0c8de3;
  border: 1px solid #0c8de3;
  color: white;
  text-align: center;
  font-size: 10px;
  margin: 2%;
  padding: 0.8%;
  border-radius: 4px;
  @media ${dispositivo.mobileL} {
    font-size: 12px;
  }
  @media ${dispositivo.tablet} {
    font-size: 15px;
  }
`;
