import React from "react";
import ReactDOM from "react-dom";
import {
  ModalBody,
  ModalContainer,
  ModalButtonContainer,
  ModalButton,
  ModalChildrenContainer,
} from "./styles";
import CloseIcon from "@material-ui/icons/Close";

export const Modal = (props) => {
  if (!props.isOpen) {
    return null;
  }
  return ReactDOM.createPortal(
    <ModalBody>
      <ModalContainer>
        <ModalButtonContainer>
          <ModalButton onClick={props.onClose}>
            <CloseIcon />
          </ModalButton>
        </ModalButtonContainer>
        <ModalChildrenContainer>{props.children}</ModalChildrenContainer>
      </ModalContainer>
    </ModalBody>,
    document.getElementById("modal")
  );
};
