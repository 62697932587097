import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../Dispositivos";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { navbar } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";

export const Navbar = styled.nav`
  display: none;
  position: fixed !important;

  @media ${dispositivo.tablet} {
    display: block;
    background: #fff;
    height: 100%;
    position: relative;
    border-right: 2px solid #0C8DE3;
    height: 100vh;
    overflow-y: auto;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #045388;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const NavbarItems = styled.div`
  align-items: center;
  background: #fff;
  width: 100%;
  display: inline-block;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  position: absolute;
  padding: inherit;
  @media ${dispositivo.tablet} {
    display: block;
  }
`;

export const NavbarItem = styled(Link)`
  color: #707070;
  padding: 15px;
  font-size: 50%;
  display: inline-block;
  @media ${dispositivo.tablet} {
    display: block;
  }
`;

export const itemNav = styled.div`
  display: inline-block;
  background: #000;
`;

//navbar navbar-expand-md navbar-light d-none d-lg-block sticky-top
export const NavItemStyledTab = styled(NavItem)`
  margin: 5%;
  margin-bottom: 0%;
  width: 96%;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
export const NavItemStyled = styled(NavItem)`
  list-style-type: none;
  padding-top: 60%;
`;
export const NavLinkStyled = styled(NavLink)`
  display: flex;
`;
export const NavLinkStyledTab = styled(NavLink)`
  display: flex;
  margin: 5px;

  align-items: center;
  text-align: center;

  @media ${dispositivo.mobileL} {
    margin: 15%;
  }
  @media ${dispositivo.tablet} {
    margin: 10px;
  }
`;
export const NavLinkStyledTabDropdown = styled(NavLink)`
  display: flex;
  margin: 15px;
  margin-right: 5px;
  align-items: center;
  text-align: center;
  padding: 3px;
`;
export const TextItemMenu = styled.h3`
  margin-left: 7px;
  padding-left: 0px;
  padding-top: 7px;
  font-size: 12px;

  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.laptop} {
    font-size: 16px;
  }
`;

export const StyledNavDropdown = styled(NavDropdown)`
  padding-left: 1px;
  margin-left: 1px;

  .nav-link {
    padding: 7px 4px;
  }
`;

export const StyledNavDropdownTab = styled(NavDropdown)`
  padding-left: 1px;
  margin-left: 1px;

  .nav-link {
    padding-top: 1px;
    padding-right: 0px;
  }
`;

export const SubItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

export const StyledCircle = styled.div`
  background: blue;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #0c8de3;
`;


export const MenuLateralWrapper = styled.div`
  .shown {
    max-height: auto;
    transition: all .4s ease;
  }

  .not-shown {
    transition: all .4s ease;
    max-height: 0;
    overflow: hidden;
  }
`