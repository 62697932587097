import React from "react";
import { HeaderPaciente } from "../HeaderPaciente/index";
import { MenuLateral } from "../MenuLateral/index";
import { useSelector } from "react-redux";
import { ContenedorPaciente } from "./styles";
import { TabMenu } from "../TabMenu/index";
export function LayoutPaciente({ url, children }) {
  return (
    <React.Fragment>
      <TabMenu url={url} urlBase="paciente" />

      <ContenedorPaciente>
        <MenuLateral url={url} urlBase="paciente" />

        <div>
          <HeaderPaciente />
          {children}
        </div>
      </ContenedorPaciente>
    </React.Fragment>
  );
}
