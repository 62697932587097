import {
  SET_ORDEN,
  NEW_ORDEN,
  ADD_ITEM,
  REMOVE_ITEM,
  UPDATE_ITEM,
} from "./ordenRxTypes";
import { COrdenRx } from "../../Entidades/OrdenRx";

export const ordenRxReducers = (state = new COrdenRx(), action) => {
  switch (action.type) {
    case SET_ORDEN:
      return action.payload;
    case NEW_ORDEN:
      return new COrdenRx();
    case ADD_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter(
          (it) =>
            it.llave !== action.payload.llave &&
            it.grupo === action.payload.grupo
        ),
      };
    case UPDATE_ITEM:
      let idx = state.items.findIndex(
        (it) =>
          it.llave == action.payload.llave && it.grupo == action.payload.grupo
      );
      let values = [...state.items];
      if (idx > -1) values[idx] = action.payload;
      return { ...state, items: values };
    default:
      return state;
  }
};
