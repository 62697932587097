import React, { useEffect, useState } from "react";

import { auth } from "../services/firebase/Config";
import { useDispatch, useSelector } from "react-redux";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [pending, setPending] = useState(true);
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.sesion);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    setPending(false);
  }, []);

  if (pending) {
    return <>Cargando...</>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
