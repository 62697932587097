import React from "react";
import { HeaderBar, HeaderWrapper } from "./styles";

const DatosPaciente = () => {
  return (
    <HeaderWrapper>
      <div className="header">
        <div className="header__user-data"></div>
      </div>
    </HeaderWrapper>
  );
};
export const HeaderPaciente = () => {
  return (
    <>
      <HeaderBar>
        <DatosPaciente />
      </HeaderBar>
    </>
  );
};
