import { db } from "../Config";

export const getPrecio = (labId, codigo) => {
  let precio = 0;
  return new Promise((resolve, reject) => {
    db.collection("tarifarios")
      .doc(codigo)
      .get()
      .then((doc) => {
        precio = doc.data().precios.find((it) => it.labId == labId).precio;

        resolve(precio);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPrecios = (codigo) => {
  let precio = 0;
  return new Promise((resolve, reject) => {
    db.collection("tarifarios")
      .doc(codigo)
      .get()
      .then((doc) => {
        resolve(doc.data().precios);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const actualizaItem = async (item) => {
  await db.collection("tarifarios").doc(item.id).set(item);
};
export const fetchTarifarios = async () => {
  let tarifarios = [];
  await db
    .collection("tarifarios")
    //.where("estado", "==", "VIGENTE")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        tarifarios.push({
          id: doc.id,
          nombre: doc.data().nombre,
          precios: doc.data().precios,
        });
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return tarifarios;
};
export const addItem = async (item) => {
  let tarifariosRef = db.collection("tarifarios").doc();
  item.id = tarifariosRef.id;
  item.estado = "VIGENTE";
  item.precios = [];
  await db
    .collection("tarifarios")
    .doc()
    .set(item)
    .catch((err) => {
      throw err;
    });
};

export const removeItem = async (item) => {
  await db
    .collection("tarifarios")
    .doc(item.id)
    .update({ estado: "ELIMINADO" })
    .catch((err) => {
      throw err;
    });
};
