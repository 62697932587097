import {
  SET_MODO_APP,
  SET_FECHA_SESION,
  SET_ESTADO_COMPLETADO,
  HTTP_REQUEST,
  HTTP_SUCCESS,
  HTTP_FAILURE,
  SET_PACIENTE_SESION,
  SET_USUARIO_SESION,
} from "./sesionTypes";

export const httpRequest = () => {
  return {
    type: HTTP_REQUEST,
  };
};

export const httpSuccess = () => {
  return {
    type: HTTP_SUCCESS,
  };
};

export const httpFailure = (error) => {
  return {
    type: HTTP_FAILURE,
    payload: error,
  };
};

export const setModoApp = (modo) => {
  return {
    type: SET_MODO_APP,
    payload: modo,
  };
};

export const setFechaSesion = (fecha) => {
  return {
    type: SET_FECHA_SESION,
    payload: fecha,
  };
};

export const setEstadoCompletado = (estado) => {
  return {
    type: SET_ESTADO_COMPLETADO,
    payload: estado,
  };
};

export const setPacienteSesion = (estado) => {
  return {
    type: SET_PACIENTE_SESION,
    payload: estado,
  };
};

export const setUsuarioSesion = (usuario) => {
  return {
    type: SET_USUARIO_SESION,
    payload: usuario,
  };
};
