import React, { useState } from 'react';
import './style.css';

const Dropdown = ({ name, variables, onSetVariable }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSetVariable = (item) => {
    setIsOpen(false);
    onSetVariable(item);
  }

  return (
    <div className="dropdown">
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        {name}
      </button>
      {isOpen && (
        <ul className="dropdown-menu-lx">
          {variables.map((variable) => (
            <a href="#" className="dropdown-item" onClick={() => handleSetVariable(variable.value)} >{variable.label}</a>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
