import React from "react";
import {
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledButton,
  ContainerLeft,
  LabelStyled,
  MainContainer,
} from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal } from "../../components/Modal/index";

export const NuevoTarifarioLabModal = (props) => {
  const sesion = useSelector((state) => state.sesion);
  const [item, setItem] = React.useState({
    nombre: "",
    precios: [],
    estado: "VIGENTE",
  });
  const history = useHistory();
  const handleChange = (e) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };

  if (sesion.loading || sesion.loading === undefined)
    return <div>...procesando</div>;
  if (sesion.error) return <div>{`Hubo un error ${sesion.error}`}</div>;
  return (
    <Modal isOpen={props.isOpen} onClose={props.handleCloseModal}>
      <ContainerTitle>
        <ContainerTitleText>Nuevo Item Tarifario</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <form
          className="form form--simple"
          onSubmit={() => props.onGuardar(item)}
        >
          <div>
            <div>
              <label className="form__labelStyled" htmlFor="nombre">
                Nombre
              </label>
              <input
                name="nombre"
                id="nombre"
                value={item.nombre}
                onChange={handleChange}
                required
              />
            </div>

            <ContainerLeft>
              <StyledButton
                type="button"
                value="Cancelar"
                onClick={() => props.handleCloseModal()}
              >
                Cancelar
              </StyledButton>

              <StyledButton type="submit" value="Guardar">
                Guardar
              </StyledButton>
            </ContainerLeft>
          </div>
        </form>
      </ContainerBody>
    </Modal>
  );
};
